<template>
  <div class="report_container">
    <el-row class="report_header">
      <el-col :span="24">{{name}}</el-col>
    </el-row>
    <el-row class="tip">
      <h1>请选择您的报告类型</h1>
    </el-row>
    <div class="report_main">
      <input type="button"
             @click="check"
             class="check"
             value="检查报告">
      <input type="button"
             @click="examine"
             class="examine"
             value="检验报告">
    </div>
    <el-button type="primary"
               @click.native="home"
               class="home">首页</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
export default {
  components: {
    Foo
  },
  data () {
    return {
      name: ''
    }
  },
  created () {
    this.name = this.$route.query.name
  },
  methods: {
    home () {
      this.$router.push('/home')
    },
    check () {
      this.$router.push({
        path: '/check',
        query: {
          name: '检查报告'
        }
      })
    },
    examine () {
      this.$router.push({
        path: '/examine',
        query: {
          name: '检验报告'
        }
      })
    }
  }
}
</script>
<style>
.report_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}
.report_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}
.report_container .tip h1 {
  font-size: 34px;
  padding-top: 30px;
  padding-bottom: 40px;
  font-weight: 400;
  color: red;
  text-align: center;
}
.report_container .report_main {
  width: 50%;
  margin: 0 auto;
}
.report_container .report_main .check {
  float: left;
  width: 170px;
  height: 170px;
  border: 0;
  background: url('../../assets/check.png') #dcdfe6 no-repeat;
  background-size: 70% 70%;
  background-position: 50% 10px;
  border-radius: 6px;
  padding-top: 120px;
  font-size: 24px;
}
.report_container .report_main .examine {
  float: left;
  width: 170px;
  height: 170px;
  border: 0;
  background: url('../../assets/examine.png') #dcdfe6 no-repeat;
  background-size: 70% 70%;
  background-position: 50% 10px;
  padding-top: 120px;
  font-size: 24px;
  border-radius: 6px;
  margin-left: 300px;
}
.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}
</style>
